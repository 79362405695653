import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Software = lazy(() => import('sections/hero/Software.js'));
const DesarrolloSoft = lazy(() => import('sections/about/DesarrolloSoft.js'));
const DesarrolloWeb = lazy(() => import('sections/services/DesarrolloWeb.js'));
const DesarrolloWordpress = lazy(() => import('sections/about/DesarrolloWordpress.js'));
const DesarrolloDevsecops = lazy(() => import('sections/hero/DesarrolloDevsecops.js'));
const DesarrolloIngSoft = lazy(() => import('sections/hero/DesarrolloIngSoft.js'));
const DesarrolloApi = lazy(() => import('sections/about/DesarrolloApi.js'));
const DesarrolloPWA = lazy(() => import('sections/about/DesarrolloPWA.js'));
const BotonDesarrollo = lazy(() => import('sections/services/BotonDesarrollo.js'));
const LlamenosGsap = lazy(() => import('sections/hero/LlamenosGsap.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {



    return (
      <div>
        <Helmet>
          <title>Desarrollo de Software en Pereira Colombia | VASCO Solutions</title>
          <meta name="description" content="Descubre nuestros servicios de desarrollo de software en Santa Rosa de Cabal y Pereira Colombia. Soluciones innovadoras y a medida, con tecnología de vanguardia." />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <Software />
            <DesarrolloSoft />
            <DesarrolloWeb />
            <DesarrolloWordpress />
            <DesarrolloDevsecops />
            <DesarrolloApi />
            <DesarrolloIngSoft />
            <DesarrolloPWA />
            <LlamenosGsap />
            <BotonDesarrollo />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`